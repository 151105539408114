export const sideBarPixels = 60

export const lyricContainerPixels = 80
export const lyricContainerMobile = 65
export const gridColumnGap = 10

// why is this in the style consts?? thats bc idk where it should go
export const debounceMS = 180

export const colors = {
  debut: '#0ca3db',
  fearless: '#ba9d45',
  red: '#951e1a',
  speak_now: '#442755',
  nineteen89: '#9dbbce',
  reputation: '#404040',
  lover: '#f262c8',
  folklore: '#7f7f7f',
  evermore: '#be7635',
  midnights: '#526d85',
  ttpd: '#968E83',
  eras_tour: '#a393eb',
  black: '#000',
}

export const confettiColors = {
  fearless: ['#69431c', '#dbc498', '#a77d41', '#ece1d2', '#caab71'],
  speak_now: ['#4a2764', '#632e6d', '#923c81', '#db7ca5', '#e2b7ce'],
  midnights: ['#537590', '#9ba8b3', '#545876', '#4c4669', '#04004b', '#1e0c6e'],
  red: ['#8C271E', '#5c4b4f', '#d9c6bb', '#ac9a94', '#692a40', '#b11a46'],
  nineteen89: ['#028090', '#78a0b4', '#8ca0b4', '#a0b4c8', '#c8c8c8'],
  ttpd: ['#ebebe7', '#dad0aa', '#c7cac4', '#a5a396', '#635b3a', '#1c21ff'],
  lover: ['#fff0c0', '#fdd2c5', '#eaadd6', '#c7b6e2', '#94bfe9'],
  reputation: ['#000000', '#aaaaaa', '#1d1c1c', '#d64605', '#463c3c'],
  folklore: ['#4d4d4d', '#929292', '#abaaaa', '#cccccc', '#eeeeee'],
  evermore: ['#d97c28', '#be7635', '#a46d3b', '#845e3d', '#654830'],
  debut: ['#34f3ff', '#00a3ad', '#6aebb4', '#97e9c1', '#c6e597'],
}

export const fonts = {
  debut: 'Tangerine',
  fearless: "'Roboto Condensed'",
  red: "'Roboto Condensed'",
  speak_now: 'Tangerine',
  nineteen89: "'Patrick Hand'",
  nineteen89_backup: 'cursive',
  reputation: 'chomskyregular',
  lover: 'pinyon_script',
  folklore: 'im_fell_dw_picaitalic',
  evermore: 'im_fell_dw_picaitalic',
  midnights: 'Roboto',
  ttpd: 'Roboto',
  eras_tour: 'pistilli-roman',
  serif: 'serif',
  sans_serif: 'sans-serif',
}
